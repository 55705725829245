import React, { useState, useEffect } from "react";
// import { useContentfulData } from "./helpers/useContentfulData";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import axios from "axios";
// import KeyboardEventHandler from "react-keyboard-event-handler";
import useEventListener from "@use-it/event-listener";
import Swipe from "react-easy-swipe";
import Div100vh from "react-div-100vh";

import "normalize.css";
import "./App.scss";

const BookPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 4rem; */
  font-size: 3vw;
  /* text-align: center; */
  padding-left: 10vw;
  padding-right: 10vw;
  position: fixed;
  /* box-sizing: border-box; */
  ${breakpoint("mobile")`
  font-size: 5.25vw;
  `}
  ${breakpoint("desktop")`
  font-size: 3vw;
  `}
`;

const RIGHT_KEY = ["39", "ArrowRight"];

function App() {
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [bookPageData, setBookPageData] = useState(null);

  const keyHandler = ({ key }) => {
    // console.log(key);
    if (RIGHT_KEY.includes(String(key))) {
      changePage();
    }
  };

  const changePage = () => {
    if (activePage !== totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const fetchBookData = async () => {
    await axios
      .get(
        "https://akinacox.flywheelsites.com/wp-json/wp/v2/posts?per_page=100&orderby=menu_order&order=asc"
      )
      .then((res) => {
        setBookPageData(res.data);
        setTotalPages(res.data.length);
      });
  };

  useEffect(() => {
    fetchBookData();
  }, []);

  useEventListener("keydown", keyHandler);

  return (
    <div className="App">
      <Swipe onSwipeLeft={changePage}>
        <Div100vh>
          {!bookPageData && (
            <BookPage>
              <div>Loading...</div>
            </BookPage>
          )}
          {bookPageData && (
            <>
              {bookPageData &&
                bookPageData.map((e, i) => {
                  const pageNumber = i + 1;
                  return (
                    <BookPage
                      key={i}
                      style={{
                        backgroundColor: e.acf.background_color,
                        color: e.acf.text_color,
                        opacity: pageNumber === activePage ? 1 : 0,
                      }}
                    >
                      {e.acf.content_type === "Image" && (
                        <div>
                          <img
                            src={e.acf.image.sizes.large}
                            alt={e.acf.image.sizes.alt}
                            style={{
                              width: "100%",
                            }}
                          />
                        </div>
                      )}
                      {e.acf.content_type === "Text" && (
                        <div
                          dangerouslySetInnerHTML={{ __html: e.acf.text }}
                        ></div>
                      )}
                    </BookPage>
                  );
                })}
            </>
          )}
        </Div100vh>
      </Swipe>
    </div>
  );
}

export default App;
